<template>
	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title-txt">My Page<br/>
						<p><nbsp n="2"/><span class="prj_ct">{{corpInfo.mberNm}}</span> 님 반갑습니다!</p><p class="pf_service"><span>[ <SelectComp type="text" list="N:무료,Y:유료" v-model="corpInfo.contractYn"/> 서비스 회원 ]</span> 이십니다!</p>
					</div>
				</div>
				<div class="contents-body tab point">
					<div class="my-resume">
						<div class="photo">
							<file-select type="image" v-model="corpInfo.imgFile" :src="corpInfo.imgSrc" defaultSrc="COMPANY" :name="corpInfo.corpNm"/>
							기업 대표 이미지로 첨부 바랍니다.<br/>이미지 미첨부시 기본이미지로 저장됩니다.
						</div>
						<div class="mem-box">
							<div class="mem-count" v-for="row in prjInChargeCnt" :key="row.mberStatusCd">
								<div class="mem">{{row.mberStatusTitle}}</div><div class="count">{{row.mberCnt}}명</div>
								<span class="noti" v-if="row.mberStatusCd == '02' && row.mberCnt > 0">!</span>
							</div>
						</div>
						<div class="point-box">
							<img src="/images/point_ico.png" alt="적립포인트"/> 적립 포인트
							<span class="point">{{corpInfo.pointSum | number}}</span>
							<div class="point-history" @click="$router.push('PIT201M01')">적립내역 &#9654;</div>
						</div>
						<img class="people" src="/images/people.png" alt="이미지"/>
					</div>
				</div>
				<div class="contents-body prj mypage">
					<div class="title"><img src="/images/people_ico.png" alt="프로젝트현황아이콘"/>프로젝트담당 신청회원 현황</div>
					<div class="filter">
						<div class="search_box">
							<SelectComp class="prj_name" v-model="srcFilter.srcCol" list="MBER_NM:담당자명,DEPT_NM:부서명,POSITION_NM:직급" @change="srcColCng($event)"/>
							<input type="text" class="search_box int" :placeholder="srcTxtPlaceholder" v-model="srcFilter.srcTxt" @keyup.enter="selectPrjInCrgList('1')"> <div class="search_btn"><img class="search" src="/images/search_btn.png" alt="검색버튼" @click="selectPrjInCrgList('1')"/></div>
						</div>
						<div class="filter_box prj_list">
							<ul>
								<li><img :class="srcFilter.ordCol == 'REG_DATE' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="searchOrder('REG_DATE')">가입일 순</p><span>ㅣ</span></li>
								<li><img :class="srcFilter.ordCol == 'MBER_NM' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="searchOrder('MBER_NM')">담당자명 순</p></li>
							</ul>
							<div class="state approval">
								승인상태
								<!-- <SelectComp type="checkbox" v-model="srcFilter.srcStatList" list="01:승인,02:미승인,11:승인불가" /> -->
								<label for="00"><input type="checkbox" name="state" id="00" value="01" v-model="srcFilter.srcStatList" @change="selectPrjInCrgList('1')">승인</label>
								<label for="01"><input type="checkbox" name="state" id="01" value="02" v-model="srcFilter.srcStatList" @change="selectPrjInCrgList('1')">미승인</label>
								<label for="02"><input type="checkbox" name="state" id="02" value="11" v-model="srcFilter.srcStatList" @change="selectPrjInCrgList('1')">승인불가</label>
							</div>
							<div class="approval_btn">
								<div class="btn" @click="updatePrjInCharge('01')">○ 승인</div>
								<div class="btn" @click="updatePrjInCharge('11')">- 승인불가</div>
							</div>
						</div>
					</div>
					<!-- 전체프로젝트 리스트 -->
					<div class="Board type3">
						<table class="Board_type3 current">
							<colgroup>
								<col width="5%">
								<col width="5%">
								<col width="8%">
								<col width="16%">
								<col width="20%">
								<col width="17%">
								<col width="5%">
								<col width="10%">
								<col width="7%">
								<col width="7%">
							</colgroup>
							<thead>
								<tr>
									<th>선택</th>
									<th>NO</th>
									<th>회원명</th>
									<th>아이디</th>
									<th>이메일</th>
									<th>부서</th>
									<th>직급</th>
									<th>회원가입일</th>
									<th>수행중인<br/>프로젝트 수</th>
									<th>승인상태</th>
								</tr>
							</thead>
							<tbody v-if="prjInChargeList.length > 0">
								<!-- 최대 20건 조회 -->
								<tr v-for="row in prjInChargeList" :key="row.mberSeq">
									<td class="radio">
										<input type="checkbox" name :id="row.rn" v-model="row.chk">
										<label :for="row.rn">
											<span></span>
										</label>
									</td>
									<td class="score">{{row.rn}}</td>
									<td class="score btn_cursor" @click="openPop(row.mberSeq)">{{row.mberNm}} &#9654;</td>
									<td class="score"><div class="new" v-if="row.mberStatusCd == '02'">new</div>{{row.loginId}}</td>
									<td class="score">{{row.email}}</td>
									<td class="score">{{row.deptNm}}</td>
									<td class="score">{{row.positionNm}}</td>
									<td class="score">{{row.regDate}}</td>
									<td class="score">{{row.projectCnt}}</td>
									<td class="score" :class="row.mberStatusCd == '01' ? 'ap' : row.mberStatusCd == '11' ? 'imp' : ''">{{row.mberStatusNm}}</td>
								</tr>
							</tbody>
							<tbody v-else>
								<!-- 프로젝트담당 신청회원이 없을 경우 -->
								<tr>
									<td colspan="10" class="none">프로젝트담당 신청회원이 없습니다!</td>
								</tr>
							</tbody>
						</table>
					</div>

					<!-- 페이징부분 -->
					<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>

				</div>
				<!-- 마이페이지 하단 배너 부분 -->
				<div class="mypage-banner">
					<div class="tit">하이프로만의 특별함!</div>
					<div class="banner">
						<img src="/images/mypage_bn01.png" alt="마이페이지배너1"/>
						<p @click="$router.push({name:'MAN001M01', query:{'target':'infoPoint'}})">자세히 보기 &#9654;</p>
					</div>
					<div class="banner">
						<img src="/images/mypage_bn02.png" alt="마이페이지배너2"/>
						<p @click="$router.push({name:'MAN001M01', query:{'target':'infoSafety'}})">자세히 보기 &#9654;</p>
					</div>
					<div class="banner">
						<img src="/images/mypage_bn03.png" alt="마이페이지배너3"/>
						<p @click="$router.push({name:'MAN001M01', query:{'target':'infoAS'}})">자세히 보기 &#9654;</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import pagingComp from '@/components/PagingComp.vue';
import fileSelect from "@/components/FileSelect.vue";

export default {
	data() {
		return {
			corpInfo : {
				corpSeq : '',
				corpNm : '',
				imgFile : '',
				imgSrc : '',
				mberNm : '',
				contractYn : '무료'
			},
			prjInChargeCnt : [],
			prjInChargeList : [],
			pageInfo : {},

			srcFilter : {
				pageIndex : '1',
				ordCol : 'REG_DATE',
				srcStatList : [],
				srcCol : 'MBER_NM',
				srcTxt : '',
			},
			srcTxtPlaceholder : '담당자명',
		}
	},
	 components : {
		pagingComp, fileSelect
	},

	beforeMount() {
		this.openMngTotMyp();
	},

	watch : {
		corpInfo : function() { this.corpImgAttach(); },
	},

	methods : {
		// 오픈시 기본정보 조회
		openMngTotMyp() {
			this.$.httpPost('/api/mem/myp/openManagementTotalMyp', '', '')
				.then(res => {
					this.corpInfo = res.data.corpInfo;
					this.corpInfo.imgSrc = '/api/mem/myp/corpImageDown?corpSeq=' + this.corpInfo.corpSeq;
					this.prjInChargeCnt = res.data.prjInChargeCnt;
					
					this.selectPrjInCrgList();
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				})
		},

		// 프로젝트 담당 신청현황 조회
		selectPrjInCrgList(div) {
			var param = this.srcFilter;
			param.corpSeq = this.corpInfo.corpSeq;
			param.pageUnit = '20';
			param.pageSize = '10';
			if(div) param.pageIndex = div;

			this.$.httpPost('/api/mem/myp/selectProjectInChargeList', param, '')
				.then(res => {
					// console.log('PRJ RESULT :', res);

					this.prjInChargeList = res.data.prjInChargeList;
					this.pageInfo = res.data.pageInfo;
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				})
		},

		// 페이징 이동
		goPage(page) {
			this.srcFilter.pageIndex = page;
			this.selectPrjInCrgList();
		},
		
		// sort 변경
		searchOrder(col) {
			this.srcFilter.ordCol = col;
			this.selectPrjInCrgList();
		},

		// 프로젝트 담당자 승인/승인불가 처리
		updatePrjInCharge(cd) {
			var prjArray = [];

			for(var i in this.prjInChargeList) {
				if(this.prjInChargeList[i].chk) {
					if(this.prjInChargeList[i].mberStatusCd == cd) {
						var errMsg = '승인';
						if(cd == '11') errMsg = '승인불가';

						alert('선택한 담당자중 이미 ' + errMsg + '된 담당자가 있습니다.');
						return false;
					}else {
						// console.log('ROW :', i);
						prjArray.push(this.prjInChargeList[i]);
					}
				}
			}

			if(prjArray.length > 0) {
				var param = {};
				param.prjInChargeList = prjArray;
				param.updateCd = cd;
				param.corpNm = this.corpInfo.corpNm;

				// console.log('PARAM :', param);

				this.$.httpPost('/api/mem/myp/updatePrjInCharge', param, '')
					.then(() => {
						// console.log('RESULT :', res);

						//this.$router.go();
						this.selectPrjInCrgList();
					})
					.catch(err => {
						// console.log('============================='); 
						// console.log(err);
						// console.log(err.response);
						// console.log('============================='); 

						alert(err.response.data.error_description);
					})
			}else {
				alert('선택한 담당자가 없습니다.');
			}	
		},

		// 기업 이미지 첨부
		corpImgAttach() {
			if(this.corpInfo.imgFile) {
				this.$.fileUpload('IMG', this.corpInfo.imgFile, '/api/mem/myp/corpImageAttachment', this.corpInfo)
					.then(res => {
						if(res.statusText == 'OK') {
							this.openMngTotMyp();
						}
					})
					.catch(err => {
						// console.log('============================='); 
						// console.log(err);
						// console.log(err.response);
						// console.log('============================='); 

						alert(err.response.data.error_description);
					})
			}
		},

		srcColCng(e) {
			this.srcTxtPlaceholder = e.target[e.target.options.selectedIndex].innerHTML.replace(' ', '');
		},

		openPop(seq) {
			var div = 'dco';
			this.$.popup('/dco/gmg/myp/MYP201P01', { seq, div });
		}
	}
}
</script>